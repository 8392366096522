.block_3 {
	display: flex;
	justify-self: center;
	align-items: center;
	border: 2px solid rgb(199, 144, 27);
	height: 700px;
	width: 1200px;
	margin: 40px auto;
	background: linear-gradient(to bottom, #004e6f 0%, #87ceeb 70%, #ffffff 100%);
	position: relative;
	overflow: hidden;
	animation: block3 2s ease 0s 1 normal forwards;
	box-shadow: 10px 10px 17px 10px #b05911;
	z-index: 2;
}
.block_3_bg {
	background-image: url("CircuitPrimary.svg");
	background-size: cover; /* или используй 100% 100%, чтобы изображение растягивалось на весь элемент */
	background-repeat: no-repeat; /* чтобы изображение не повторялось */
	height: 100%;
	width: auto;
	background-repeat: no-repeat;
	background-size: cover;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	align-content: center;
	font-size: clamp(10px, 2vw, 30px);
}

@keyframes block3 {
	0% {
		opacity: 0;
		transform: translateX(-50px);
	}
	50% {
		opacity: 1;
		transform: translateX(0);
	}
	100% {
		transform: translateX(0);
	}
}
.earth {
	background-color: darkgreen;
	clip-path: circle(42.7% at 8% 100%);
	height: 400px;
	width: 647px;
	position: absolute;
	bottom: 1px;
}
.earth2 {
	background-color: rgb(10, 129, 10);
	clip-path: circle(19.8% at 35% 76%);
	height: 265px;
	width: 1095px;
	position: absolute;
	bottom: -18px;
}
.earth3 {
	background-color: rgb(14, 73, 14);
	clip-path: circle(33.7% at 59% 100%);
	height: 363px;
	width: 1302px;
	position: absolute;
	bottom: -86px;
	z-index: 2;
}
.earth4 {
	background-color: rgb(2, 63, 2);
	clip-path: circle(33.7% at 99% 96%);
	height: 355px;
	width: 675px;
	position: absolute;
	bottom: -4px;
	right: -5px;
}

.trees {
	clip-path: polygon(
		10% 25%,
		46% 35%,
		50% 25%,
		53% 0,
		62% 41%,
		78% 13%,
		70% 47%,
		62% 60%,
		65% 100%,
		35% 100%,
		43% 50%,
		34% 37%
	);
	background-color: rgb(43, 17, 5);
	height: 134px;
	width: 150px;
	position: absolute;
	bottom: 150px;
	right: 13px;
	z-index: 3;
}
.trees2 {
	clip-path: polygon(
		16% 42%,
		48% 42%,
		45% 2%,
		55% 26%,
		60% 45%,
		79% 13%,
		69% 37%,
		59% 58%,
		59% 100%,
		44% 100%,
		48% 70%,
		47% 51%
	);
	background-color: rgb(43, 17, 5);
	height: 134px;
	width: 150px;
	position: absolute;
	bottom: 150px;
	left: 200px;
	z-index: 3;
}
.trees2_green {
	clip-path: ellipse(25% 40% at 50% 50%);
	z-index: 2;
	position: absolute;
	border: 1px solid green;
	height: 203px;
	width: 275px;
	bottom: 161px;
	left: 133px;
	box-shadow: -54px -37px 10px 1px #093b02 inset;
	animation: myAnimeanim 4s ease 0s infinite alternate forwards;
}
.trees2_green2 {
	clip-path: ellipse(25% 40% at 50% 50%);
	z-index: 2;
	background-color: #384f15;
	position: absolute;
	border: 1px solid #0d290d;
	height: 210px;
	width: 236px;
	bottom: 157px;
	right: -24px;
	box-shadow: -44px -37px 10px 1px #093b02 inset;
	animation: myAnimeanim 6s ease 0s infinite alternate forwards;
}
@keyframes myAnimeanim {
	0%,
	100% {
		transform: rotate(0deg);
		transform-origin: 50% 100%;
	}

	10% {
		transform: rotate(2deg);
	}

	20%,
	40%,
	60% {
		transform: rotate(-3deg);
	}

	30%,
	50%,
	70% {
		transform: rotate(3deg);
	}

	80% {
		transform: rotate(-2deg);
	}

	90% {
		transform: rotate(1deg);
	}
}
.trees3 {
	clip-path: polygon(
		0 26%,
		33% 37%,
		46% 2%,
		42% 41%,
		62% 38%,
		84% 13%,
		71% 40%,
		45% 56%,
		57% 100%,
		42% 100%,
		32% 57%,
		22% 40%
	);
	background-color: rgb(44, 18, 6);
	height: 134px;
	width: 150px;
	position: absolute;
	bottom: 180px;
	left: 600px;
	z-index: 3;
}
.trees3_green3 {
	clip-path: ellipse(25% 40% at 50% 50%);
	z-index: 2;
	background-color: #508400;
	position: absolute;
	border: 1px solid #0d290d;
	height: 185px;
	width: 312px;
	bottom: 206px;
	right: 379px;
	box-shadow: -44px -37px 10px 1px #093b02 inset;
	animation: myAnimeanim 6s ease 0s infinite alternate forwards;
}
.oblako {
	position: absolute;
	height: 215px;
	width: 259px;
	top: 100px;
	left: 100px;
	z-index: 2;
	animation: moveClouds 27s linear infinite alternate;
}
.oblako2 {
	z-index: 2;
	position: absolute;
	height: 168px;
	width: 263px;
	top: 8px;
	left: 764px;
	animation: moveClouds 24s linear infinite alternate;
}
.oblako3 {
	z-index: 2;
	position: absolute;
	height: 167px;
	width: 291px;
	top: 140px;
	left: 500px;
	animation: moveClouds 30s linear infinite alternate;
}
.oblako4 {
	z-index: 2;
	position: absolute;
	height: 135px;
	width: 170px;
	top: -27px;
	left: 516px;
	animation: moveClouds 20s linear infinite alternate;
}
.trees,
.trees2 {
	background-color: #654321;
	border-bottom: solid 5px #432;
}

.trees2_green,
.trees2_green2 {
	background-color: #228b22;
	border-radius: 50%;
}
@keyframes moveClouds {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(50%);
	}
}

.trees,
.trees2 {
	box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
}

.block_3 .sun {
	position: absolute;
	top: 30px;
	right: 985px;
	width: 160px;
	height: 160px;
	background-color: #ffdd00;
	border-radius: 50%;
	box-shadow: 0 0 50px #ffdd00;
}
.trava1,
.trava2,
.trava3,
.trava4,
.trava5,
.trava6,
.trava7,
.trava8,
.trava9,
.trava10,
.trava11,
.trava12,
.trava13,
.trava21,
.trava22,
.trava23,
.trava24,
.trava25,
.trava26,
.trava27,
.trava28,
.trava29 {
	background-color: #228b22;
	position: absolute;
	z-index: 3;
	clip-path: polygon(
		57% 4%,
		59% 71%,
		98% 35%,
		80% 78%,
		79% 91%,
		50% 91%,
		21% 91%,
		20% 76%,
		8% 29%,
		37% 74%
	);
}
.trava1 {
	bottom: 125px;
	left: 30px;
	height: 50px;
	width: 50px;
	animation: myAnimeanim 4s ease 0s infinite alternate forwards;
}
.trava2 {
	bottom: 105px;
	left: 735px;
	height: 50px;
	width: 50px;
	animation: myAnimeanim 3s ease 0s infinite alternate forwards;
}
.trava3 {
	bottom: 25px;
	left: 560px;
	height: 50px;
	width: 50px;
	animation: myAnimeanim 6s ease 0s infinite alternate forwards;
}
.trava4 {
	bottom: 75px;
	left: 170px;
	height: 50px;
	width: 50px;
	animation: myAnimeanim 4.6s ease 0s infinite alternate forwards;
}
.trava5 {
	bottom: 35px;
	left: 50px;
	height: 50px;
	width: 50px;
	animation: myAnimeanim 6.4s ease 0s infinite alternate forwards;
}
.trava6 {
	bottom: 40px;
	left: 427px;
	height: 50px;
	width: 50px;
	animation: myAnimeanim 5.4s ease 0s infinite alternate forwards;
}
.trava7 {
	bottom: 125px;
	left: 390px;
	height: 50px;
	width: 50px;
	animation: myAnimeanim 4.8s ease 0s infinite alternate forwards;
}
.trava8 {
	bottom: 155px;
	left: 130px;
	height: 50px;
	width: 50px;
	animation: myAnimeanim 3.4s ease 0s infinite alternate forwards;
}
.trava9 {
	bottom: 133px;
	left: 550px;
	height: 50px;
	width: 50px;
	animation: myAnimeanim 5.2s ease 0s infinite alternate forwards;
}
.trava21 {
	bottom: 6px;
	left: 130px;
	height: 50px;
	width: 50px;
	animation: myAnimeanim 4s ease 0s infinite alternate forwards;
}
.trava22 {
	bottom: 125px;
	left: 653px;
	height: 50px;
	width: 50px;
	animation: myAnimeanim 3s ease 0s infinite alternate forwards;
}
.trava23 {
	bottom: 66px;
	left: 500px;
	height: 50px;
	width: 50px;
	animation: myAnimeanim 6s ease 0s infinite alternate forwards;
}
.trava24 {
	bottom: 95px;
	left: 270px;
	height: 54px;
	width: 50px;
	animation: myAnimeanim 4.6s ease 0s infinite alternate forwards;
}
.trava25 {
	bottom: 9px;
	left: 303px;
	height: 50px;
	width: 50px;
	animation: myAnimeanim 6.4s ease 0s infinite alternate forwards;
}
.trava26 {
	bottom: 2px;
	left: 1066px;
	height: 50px;
	width: 50px;
	animation: myAnimeanim 5.4s ease 0s infinite alternate forwards;
}
.trava27 {
	bottom: 183px;
	left: 778px;
	height: 50px;
	width: 50px;
	animation: myAnimeanim 4.8s ease 0s infinite alternate forwards;
}
.trava28 {
	bottom: 133px;
	left: 846px;
	height: 50px;
	width: 50px;
	animation: myAnimeanim 3.4s ease 0s infinite alternate forwards;
}
.trava29 {
	bottom: 125px;
	left: 962px;
	height: 50px;
	width: 50px;
	animation: myAnimeanim 5.2s ease 0s infinite alternate forwards;
}
.trava10 {
	bottom: 35px;
	left: 713px;
	height: 50px;
	width: 50px;
	animation: myAnimeanim 3.7s ease 0s infinite alternate forwards;
}
.trava11 {
	bottom: 18px;
	left: 855px;
	height: 50px;
	width: 50px;
	animation: myAnimeanim 4.5s ease 0s infinite alternate forwards;
}
.trava12 {
	bottom: 90px;
	left: 1105px;
	height: 50px;
	width: 50px;
	animation: myAnimeanim 7s ease 0s infinite alternate forwards;
}
.trava13 {
	bottom: 51px;
	left: 970px;
	height: 50px;
	width: 50px;
	animation: myAnimeanim 4.8s ease 0s infinite alternate forwards;
}
.home {
	clip-path: polygon(32% 0, 91% 0, 86% 100%, 26% 100%);
	background-color: #432;
	height: 92px;
	width: 78px;
	position: absolute;
	top: 421px;
	left: 47px;

	left: 46px;
	z-index: 2;
}
.home2 {
	clip-path: polygon(
		0 29%,
		39% 39%,
		34% 0,
		73% 1%,
		59% 40%,
		100% 34%,
		100% 74%,
		60% 63%,
		74% 100%,
		25% 100%,
		39% 63%,
		0 69%
	);
	background-color: #432;
	position: absolute;
	height: 131px;
	width: 144px;
	position: absolute;
	top: 358px;
	left: 22px;

	z-index: 2;
	animation: spin 2s linear infinite;
}
@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
.nlo {
	clip-path: polygon(26% 32%, 75% 33%, 100% 50%, 75% 65%, 25% 65%, 0% 50%);
	background-color: rgb(24, 14, 4);
	height: 135px;
	width: 135px;
	position: absolute;
	top: 25px;
	left: 173px;
	z-index: 3;
	animation: nlo 4s ease 0s alternate;
}
@keyframes nlo {
	0% {
		opacity: 0;
		transform: translateY(-250px);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}
.nlo_1 {
	clip-path: circle(47.9% at 51% 100%);
	position: absolute;
	top: 81px;
	right: -36px;
	width: 248px;
	height: 4px;
	background-color: #db230b;
	z-index: 3;
}
.laser {
	clip-path: polygon(0 0, 6% 0, 100% 100%, 92% 100%);
	background-color: red;
	height: 1px;
	width: 1px;
	position: absolute;
	top: 77px;
	left: 229px;
	z-index: -1;
	animation: laser 2s ease 4.2s alternate;
}
@keyframes laser {
	0%,
	20% {
		height: 1px;
		width: 1px;
		opacity: 0;
	}
	40%,
	60% {
		height: 560px;
		width: 330px;
		opacity: 1;
	}
	100% {
		height: 560px;
		width: 365px;
		opacity: 1;
	}
}
.expouse {
	height: 1px;
	width: 59px;
	position: absolute;
	top: 659px;
	left: 510px;
	z-index: -1;
	box-shadow: -1px 0px 150px 70px #ffdd00;
	animation: expole 2s ease 6.3s 1 normal forwards;
}
@keyframes expole {
	0% {
		opacity: 0;
		transform: translateY(0);
	}

	100% {
		opacity: 1;
		transform: translateY(-170px);
	}
}
.oblako_1 {
	box-shadow: 0px -20px 14px 7px #b1aaaa inset;
	background-color: #ebeaea;
	clip-path: circle(50% at 50% 50%);
	position: absolute;
	height: 100px;
	width: 100px;
	bottom: 0px;
	right: 0px;
	z-index: 2;
}
.oblako_2 {
	box-shadow: 0px -20px 14px 7px #b1aaaa inset;
	background-color: #dddbdb;
	clip-path: circle(50% at 50% 50%);
	position: absolute;
	height: 84px;
	width: 84px;
	bottom: 0px;
	right: 111px;
	z-index: 2;
}
.oblako_3 {
	box-shadow: 0px -20px 14px 7px #b1aaaa inset;
	background-color: #ffffff;
	clip-path: circle(50% at 50% 50%);
	position: absolute;
	height: 95px;
	width: 95px;
	bottom: -6px;
	right: 55px;
	z-index: 2;
}
.oblako_4 {
	box-shadow: 0px -20px 14px 7px #b1aaaa inset;
	background-color: #ffffff;
	clip-path: circle(50% at 50% 50%);
	position: absolute;
	height: 37px;
	width: 37px;
	bottom: 11px;
	right: -20px;
	z-index: 2;
}
.oblako_5 {
	box-shadow: 0px -20px 14px 7px #b1aaaa inset;
	background-color: #f9f4f4;
	clip-path: circle(50% at 50% 50%);
	position: absolute;
	height: 38px;
	width: 38px;
	bottom: 22px;
	right: 182px;
	z-index: 2;
}
.oblako_6 {
	box-shadow: 0px -20px 14px 7px #b1aaaa inset;
	background-color: #efebeb;
	clip-path: circle(50% at 50% 50%);
	position: absolute;
	height: 22px;
	width: 22px;
	bottom: 71px;
	right: 118px;
	z-index: 2;
}
.oblako_7 {
	box-shadow: 0px -20px 14px 7px #b1aaaa inset;
	background-color: #e7dddd;
	clip-path: circle(50% at 50% 50%);
	position: absolute;
	height: 57px;
	width: 57px;
	bottom: 37px;
	right: -13px;
	z-index: 2;
}
.oblako_8 {
	box-shadow: 0px -20px 14px 7px #b1aaaa inset;
	background-color: #f3eded;
	clip-path: circle(50% at 50% 50%);
	position: absolute;
	height: 47px;
	width: 47px;
	bottom: -13px;
	right: 41px;
	z-index: 2;
}
.oblako_2_1 {
	box-shadow: 0px -20px 14px 7px #b1aaaa inset;
	background-color: #f0f0f0;
	clip-path: circle(50% at 50% 50%);
	position: absolute;
	height: 105px; /* немного увеличен размер по сравнению с .oblako_1 */
	width: 105px;
	bottom: 10px; /* изменено положение относительно нижнего края */
	right: 10px; /* изменено положение относительно правого края */
	z-index: 2;
}

.oblako_2_2 {
	box-shadow: 0px -20px 14px 7px #b1aaaa inset;
	background-color: #e6e6e6;
	clip-path: circle(50% at 50% 50%);
	position: absolute;
	height: 80px; /* размер как у .oblako_2, но изменен цвет */
	width: 80px;
	bottom: 5px; /* чуть выше, чем .oblako_2 */
	right: 130px; /* чуть правее, чем .oblako_2 */
	z-index: 2;
}

.oblako_2_3 {
	box-shadow: 0px -20px 14px 7px #b1aaaa inset;
	background-color: #f8f8f8;
	clip-path: circle(50% at 50% 50%);
	position: absolute;
	height: 90px; /* размер между .oblako_1 и .oblako_3 */
	width: 90px;
	bottom: 0px;
	right: 70px; /* положение посередине между .oblako_3 и .oblako_2 */
	z-index: 2;
}

.oblako_2_4 {
	box-shadow: 0px -20px 14px 7px #b1aaaa inset;
	background-color: #ececec;
	clip-path: circle(50% at 50% 50%);
	position: absolute;
	height: 40px; /* размер как у .oblako_4, но изменен цвет */
	width: 40px;
	bottom: 20px; /* чуть выше, чем .oblako_4 */
	right: -30px; /* чуть левее, чем .oblako_4 */
	z-index: 2;
}

.oblako_2_5 {
	box-shadow: 0px -20px 14px 7px #b1aaaa inset;
	background-color: #f5f5f5;
	clip-path: circle(50% at 50% 50%);
	position: absolute;
	height: 35px; /* меньше, чем .oblako_5 */
	width: 35px;
	bottom: 30px; /* выше, чем .oblako_5 */
	right: 200px; /* правее, чем .oblako_5 */
	z-index: 2;
}

.oblako_2_6 {
	box-shadow: 0px -20px 14px 7px #b1aaaa inset;
	background-color: #ededed;
	clip-path: circle(50% at 50% 50%);
	position: absolute;
	height: 25px; /* размер как у .oblako_6, но изменен цвет */
	width: 25px;
	bottom: 80px; /* чуть выше, чем .oblako_6 */
	right: 135px; /* чуть правее, чем .oblako_6 */
	z-index: 2;
}

.oblako_2_7 {
	box-shadow: 0px -20px 14px 7px #b1aaaa inset;
	background-color: #eaeaea;
	clip-path: circle(50% at 50% 50%);
	position: absolute;
	height: 60px; /* немного больше, чем .oblako_7 */
	width: 60px;
	bottom: 45px; /* чуть выше, чем .oblako_7 */
	right: -20px; /* положение как у .oblako_7 */
	z-index: 2;
}

.oblako_2_8 {
	box-shadow: 0px -20px 14px 7px #b1aaaa inset;
	background-color: #f7f7f7;
	clip-path: circle(50% at 50% 50%);
	position: absolute;
	height: 50px; /* немного больше, чем .oblako_8 */
	width: 50px;
	bottom: -10px; /* чуть ниже, чем .oblako_8 */
	right: 60px; /* чуть правее, чем .oblako_8 */
	z-index: 2;
}
.oblako_3_1 {
	box-shadow: 0px -20px 14px 7px #b1aaaa inset;
	background-color: #e6e6e6;
	clip-path: circle(50% at 50% 50%);
	position: absolute;
	height: 80px;
	width: 80px;
	bottom: 30px;
	right: 128px;
	z-index: 2;
}

.oblako_3_2 {
	box-shadow: 0px -20px 14px 7px #b1aaaa inset;
	background-color: #f0f0f0;
	clip-path: circle(50% at 50% 50%);
	position: absolute;
	height: 73px;
	width: 73px;
	bottom: 40px;
	right: 95px;
	z-index: 2;
}

.oblako_3_3 {
	box-shadow: 0px -20px 14px 7px #b1aaaa inset;
	background-color: #f8f8f8;
	clip-path: circle(50% at 50% 50%);
	position: absolute;
	height: 67px;
	width: 67px;
	bottom: 45px;
	right: 50px;
	z-index: 2;
}

.oblako_3_4 {
	box-shadow: 0px -20px 14px 7px #b1aaaa inset;
	background-color: #ececec;
	clip-path: circle(50% at 50% 50%);
	position: absolute;
	height: 55px; /* Самые маленькие круги */
	width: 55px;
	bottom: 35px;
	right: 30px;
	z-index: 2;
}
.oblako_3_5 {
	box-shadow: 0px -20px 14px 7px #b1aaaa inset;
	background-color: #f5f5f5;
	clip-path: circle(50% at 50% 50%);
	position: absolute;
	height: 48px;
	width: 48px;
	bottom: 30px;
	right: 172px;
	z-index: 2;
}

.oblako_3_6 {
	box-shadow: 0px -20px 14px 7px #b1aaaa inset;
	background-color: #ededed;
	clip-path: circle(50% at 50% 50%);
	position: absolute;
	height: 45px; /* размер как у .oblako_6, но изменен цвет */
	width: 45px;
	bottom: 80px; /* чуть выше, чем .oblako_6 */
	right: 135px; /* чуть правее, чем .oblako_6 */
	z-index: 2;
}
.oblako_3_7 {
	box-shadow: 0px -20px 14px 7px #b1aaaa inset;
	background-color: #eaeaea;
	clip-path: circle(50% at 50% 50%);
	position: absolute;
	height: 60px;
	width: 60px;
	bottom: 25px;
	right: 82px;
	z-index: 2;
}
.oblako_3_8 {
	box-shadow: 0px -20px 14px 7px #b1aaaa inset;
	background-color: #f7f7f7;
	clip-path: circle(50% at 50% 50%);
	position: absolute;
	height: 17px;
	width: 17px;
	bottom: 46px;
	right: 20px;
	z-index: 2;
}
.oblako_5_1 {
	box-shadow: 0px -20px 14px 7px #b1aaaa inset;
	background-color: #f0f0f0;
	clip-path: circle(50% at 50% 50%);
	position: absolute;
	height: 55px;
	width: 55px;
	bottom: 5px;
	right: 38px;
	z-index: 2;
}

.oblako_5_2 {
	box-shadow: 0px -20px 14px 7px #b1aaaa inset;
	background-color: #e6e6e6;
	clip-path: circle(50% at 50% 50%);
	position: absolute;
	height: 80px; /* размер как у .oblako_2, но изменен цвет */
	width: 80px;
	bottom: 5px; /* чуть выше, чем .oblako_2 */
	right: 130px; /* чуть правее, чем .oblako_2 */
	z-index: 2;
}

.oblako_5_3 {
	box-shadow: 0px -20px 14px 7px #b1aaaa inset;
	background-color: #f8f8f8;
	clip-path: circle(50% at 50% 50%);
	position: absolute;
	height: 90px; /* размер между .oblako_1 и .oblako_3 */
	width: 90px;
	bottom: 0px;
	right: 70px; /* положение посередине между .oblako_3 и .oblako_2 */
	z-index: 2;
}

.oblako_5_4 {
	box-shadow: 0px -20px 14px 7px #b1aaaa inset;
	background-color: #ececec;
	clip-path: circle(50% at 50% 50%);
	position: absolute;
	height: 40px;
	width: 40px;
	bottom: 20px;
	right: 12px;
	z-index: 2;
}
.oblako_5_5 {
	box-shadow: 0px -20px 14px 7px #b1aaaa inset;
	background-color: #f5f5f5;
	clip-path: circle(50% at 50% 50%);
	position: absolute;
	height: 35px; /* меньше, чем .oblako_5 */
	width: 35px;
	bottom: 30px; /* выше, чем .oblako_5 */
	right: 200px; /* правее, чем .oblako_5 */
	z-index: 2;
}
.sun2 {
	position: absolute;
	top: 54px;
	right: 85px;
	width: 21px;
	height: 21px;
	background-color: #db230b;
	border-radius: 50%;
	z-index: 4;
}
.sun3 {
	position: absolute;
	top: 54px;
	right: 54px;
	width: 21px;
	height: 21px;
	background-color: #db230b;
	border-radius: 50%;
	z-index: 4;
}
.sun4 {
	position: absolute;
	top: 54px;
	right: 24px;
	width: 21px;
	height: 21px;
	background-color: #db230b;
	border-radius: 50%;
	z-index: 4;
}
.expouse_1,
.expouse_2,
.expouse_3,
.expouse_4,
.expouse_5,
.expouse_6,
.expouse_7,
.expouse_8,
.expouse_9,
.expouse_10 {
	background: radial-gradient(#ffea00 15%, #ff0000 94%);
	position: absolute;
	box-shadow: #0d290d;
}
.expouse_1 {
	width: 137px;
	height: 124px;
	top: -31px;
	right: -25px;
	clip-path: ellipse(14% 50% at 50% 50%);
}
.expouse_2 {
	width: 99px;
	height: 80px;
	top: 29px;
	right: 0px;
	clip-path: ellipse(50% 21% at 50% 50%);
}
.expouse_3 {
	width: 94px;
	height: 104px;
	top: -89px;
	right: -5px;
	clip-path: ellipse(50% 21% at 50% 50%);
}
.expouse_4 {
	width: 11px;
	height: 241px;
	top: -82px;
	right: 38px;
	opacity: 0.6;
	clip-path: ellipse(50% 21% at 50% 50%);
}
.expouse_5 {
	width: 179px;
	height: 117px;
	top: -94px;
	right: -44px;
	clip-path: ellipse(50% 21% at 50% 50%);
	opacity: 0.6;
}
.expouse_6 {
	width: 223px;
	height: 99px;
	top: -83px;
	right: -63px;
	clip-path: ellipse(50% 7% at 50% 50%);
	opacity: 0.8;
}
.expouse_7 {
	width: 92px;
	height: 43px;
	top: -20px;
	right: -1px;
	clip-path: ellipse(50% 7% at 50% 50%);
	opacity: 0.6;
}
.mount {
	position: absolute;
	height: 300px;
	width: 300px;
	top: -46px;
	left: -44px;
	z-index: -1;
}
.mount_1 {
	clip-path: polygon(63% 0, 0% 100%, 100% 100%);
	height: 308px;
	width: 246px;
	top: 351px;
	left: 900px;
	position: absolute;
	background-color: #432;
}
.mount_2 {
	clip-path: polygon(50% 0, 0% 100%, 100% 100%);
	height: 300px;
	width: 300px;
	top: 291px;
	left: 785px;
	position: absolute;
	background-color: rgb(39, 29, 19);
}
.mount_3 {
	clip-path: polygon(21% 22%, 0% 100%, 100% 100%);
	height: 200px;
	width: 200px;
	top: 362px;
	left: 768px;
	position: absolute;
	background-color: rgb(39, 29, 19);
}
.mount_4 {
	clip-path: polygon(
		53% 0,
		70% 18%,
		93% 48%,
		66% 32%,
		46% 46%,
		30% 35%,
		3% 41%
	);
	background-color: white;
	height: 313px;
	width: 123px;
	top: 345px;
	left: 989px;
	position: absolute;
}
.mount_5 {
	clip-path: polygon(
		50% 0%,
		100% 100%,
		73% 67%,
		57% 90%,
		43% 59%,
		26% 83%,
		0 100%
	);
	background-color: white;
	height: 153px;
	width: 154px;
	top: 291px;
	left: 858px;
	position: absolute;
}
.mount_6 {
	clip-path: polygon(40% 0, 15% 81%, 44% 61%);
	background-color: white;
	height: 131px;
	width: 111px;
	top: 409px;
	left: 763px;
	position: absolute;
}
@media (max-width: 1300px) {
	.block_3 {
		scale: 0.87;
	}
}

@media (max-width: 1200px) {
	.block_3 {
		scale: 0.8;
	}
}

@media (max-width: 1100px) {
	.block_3 {
		scale: 0.75;
		margin: -30px auto;
	}
}

@media (max-width: 1000px) {
	.block_3 {
		scale: 0.66;
		margin: -45px auto;
	}
}

@media (max-width: 900px) {
	.block_3 {
		scale: 0.58;
		margin: -70px auto;
	}
}

@media (max-width: 800px) {
	.block_3 {
		scale: 0.52;
		margin: -125px auto;
	}
}

@media (max-width: 700px) {
	.block_3 {
		scale: 0.4;
		margin: -180px auto;
	}
}

@media (max-width: 600px) {
	.block_3 {
		scale: 0.34;
		margin: -216px auto;
	}
}

@media (max-width: 500px) {
	.block_3 {
		scale: 0.25;
	}
}

@media (max-width: 400px) {
	.block_3 {
		scale: 0.24;
	}
}
@media (max-width: 350px) {
	.block_3 {
		scale: 0.18;
	}
}
@media (max-width: 300px) {
	.block_3 {
		scale: 0.15;
	}
}

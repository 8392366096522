.todo {
	display: flex;
	align-items: center;
	padding: 10px 20px;
	margin: 15px 5%;
	font-size: clamp(16px, 2vw, 22px);
	border-radius: 5px;
	border: 2px solid #000000;
	box-shadow: 0 8px 20px 8px black;
	color: #000000;
	background-color: #fbfef9;
	justify-content: space-between;
	animation: slideGradientRightBlock22 1.5s ease 0s 1 normal forwards;
}
@keyframes slideGradientRightBlock22 {
	0% {
		opacity: 0;
		transform: translateX(-30px);
		transform: translateY(-10px);
	}

	100% {
		opacity: 1;
		transform: translateX(0);
		transform: translateY(0);
	}
}
.todoText {
	width: 90%;
	text-align: left;
	word-wrap: break-word;
	text-shadow: none;
}

.todoIcon {
	font-size: clamp(22px, 2vw, 28px);
	margin-right: 10px;
	color: #000000;
}

.completedTodo {
	background-color: unset;
	border-color: gray;
	color: gray;
}

.todo.completedTodo .todoIcon,
.todo.completedTodo .checkIcon,
.todo.completedTodo .deleteIcon {
	color: gray;
}

.checkIcon,
.deleteIcon {
	cursor: pointer;
	color: lightgrey;
	padding: 0 7px;
	font-size: clamp(28px, 2vw, 36px);
	transition: transform 0.3s;
}

.checkIcon:hover,
.deleteIcon:hover {
	cursor: pointer;
	transform: scale(1.3);
}

.checkIcon:hover {
	color: green;
}

.deleteIcon:hover {
	color: red;
}

.site-footer {
	background-color: #001122;
	color: #fff;
	padding: 20px 0;
	font-family: "Arial", sans-serif;
	border-top: 2px solid #f7770e;
}

.footer-content {
	display: flex;
	justify-content: space-between;
	margin: 0 auto;
	padding: 0 20px;
	max-width: 1200px;
	justify-content: center;
	gap: 15px;
}

.footer-section h3 {
	margin-bottom: 10px;
	font-size: clamp(11px, 2vw, 14px);
}

.footer-section p {
	font-size: clamp(8px, 2vw, 12px);
}

.social-links {
	list-style: none;
	padding: 0;
	/* display: flex; */
}

.social-links li {
	display: inline;
	margin-right: 10px;
}

.social-links li a {
	color: #ff7e00;
	text-decoration: none;
	transition: color 0.3s ease;
	font-size: clamp(10px, 2vw, 14px);
}

.social-links li a:hover {
	color: #ffa500;
}

.footer-bottom {
	text-align: center;
	padding-top: 10px;
	font-size: clamp(6px, 2vw, 10px);
}

@media (max-width: 800px) {
	.footer-content {
		flex-direction: column;
		align-items: center;
	}

	.footer-section {
		margin-bottom: 20px;
	}
}

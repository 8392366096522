.block_1_console {
	background-color: #1e1e1e; /* Темный фон, характерный для консоли */
	color: #d4d4d4; /* Цвет текста - обычно светлый для контраста */
	font-family: "Courier New", monospace; /* Шрифт с фиксированной шириной для имитации вывода консоли */
	margin: 50px 0 40px 50px; /* Отступы внутри блока */
	border-radius: 18px; /* Скругление углов */
	box-shadow: 6px 13px 3px 5px rgba(0, 0, 0, 0.5); /* Тень для создания эффекта глубины */
	width: 49%;
	padding-top: 6px;
}

.block_1_console_win {
	white-space-collapse: preserve;
	overflow-wrap: break-word;
	background-color: #0f0f0f;
	height: 100%;
	padding: 20px;
	text-align: left;
	border-radius: 0 0 18px 18px;
	font-size: clamp(12px, 2vw, 16px);
	width: 100%;
	display: inline-block;
}
.block_1_console_win p {
	width: 100%;
	display: inline-block;
}
.highlight {
	color: red;
}
.block1-info {
	width: 49%;
	float: right;
	margin: 25px 10px 0 10px;
	font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
	color: #f7770e;
	font-size: clamp(18px, 2vw, 30px);
}
@keyframes slideGradientUpBlock1 {
	0% {
		opacity: 0;
		transform: translateX(-50px);
	}

	100% {
		opacity: 1;
		transform: translateX(0);
	}
}
.block_1_flex {
	display: flex;
	height: 100%;
	background-image: url("WaveLine.svg");
	background-size: cover; /* или используй 100% 100%, чтобы изображение растягивалось на весь элемент */
	background-repeat: no-repeat; /* чтобы изображение не повторялось */
	animation: slideGradientUpBlock1 1s ease 0s 1 normal forwards;
}

.block1-info-text {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr 1fr;
	margin: 8px;
	padding: 30px;
	color: #f7770e;
	font-size: clamp(32px, 2vw, 44px);
	gap: 35px;
	margin-top: 35px;
}

.block1-info-text a {
	color: #f7770e;
	text-decoration: none;
	transition: color 0.3s ease, text-shadow 0.5s ease;
}

.block1-info-text a:active {
	color: rgb(255, 0, 0);
	text-shadow: 1px 1px 0px #ffffff, 0px -1px 0px #d6d0ca, -1px 1px 0px #e6b892,
		1px -1px 0px #a19f9d;
}
.block1-info-text a:nth-child(6) {
	animation: social 2s cubic-bezier(0.34, 1.56, 0.64, 1) normal forwards;
}

.block1-info-text a:nth-child(5) {
	animation: social 2.5s cubic-bezier(0.34, 1.56, 0.64, 1) normal forwards;
}

.block1-info-text a:nth-child(4) {
	animation: social 3s cubic-bezier(0.34, 1.56, 0.64, 1) normal forwards;
}

.block1-info-text a:nth-child(3) {
	animation: social 3.5s cubic-bezier(0.34, 1.56, 0.64, 1) normal forwards;
}

.block1-info-text a:nth-child(2) {
	animation: social 4s cubic-bezier(0.34, 1.56, 0.64, 1) normal forwards;
}

.block1-info-text a:nth-child(1) {
	animation: social 4.4s cubic-bezier(0.34, 1.56, 0.64, 1) normal forwards;
}
.block1-info-text a p {
	font-size: clamp(16px, 2vw, 33px);
}

@keyframes social {
	0% {
		animation-timing-function: ease-in;
		opacity: 0;
		transform: translateY(-350px);
	}

	38% {
		animation-timing-function: ease-out;
		opacity: 1;
		transform: translateY(0);
	}

	55% {
		animation-timing-function: ease-in;
		transform: translateY(-85px);
	}

	72% {
		animation-timing-function: ease-out;
		transform: translateY(0);
	}

	81% {
		animation-timing-function: ease-in;
		transform: translateY(-38px);
	}

	90% {
		animation-timing-function: ease-out;
		transform: translateY(0);
	}

	95% {
		animation-timing-function: ease-in;
		transform: translateY(-8px);
	}

	100% {
		animation-timing-function: ease-out;
		transform: translateY(0);
	}
}
.block1-info-text a:hover {
	color: #f3f2f2;
}

@media (max-width: 800px) {
	.block_1_flex {
		flex-direction: column;
	}
	.block_1_console {
		margin: 0;
		width: 96%;
		height: auto;
	}
	.block1-info {
		width: 96%;
	}
	.block1-info-text {
		text-shadow: 3px 1px 5px #000000;
	}
}

:root {
	--BGblack: #001122;
	--borderPink: 0 0 0.2rem #fff, 0 0 0.2rem #fff, 0 0 2rem #f5913f,
		0 0 0.8rem #f7770e, 0 0 2.8rem #be5601, inset 0 0 1.3rem #612d02;
	--textShadow: 0 0 1px black, 0 0 18px #f3b666, 0 0 14px #f0bc5b,
		0 0 22px #f7770e, 0 0 32px #db6708, 0 0 42px #b45303;
	--boxShadow: 0 0 3px #fff, 0 0 5px #fff2e0, 0 0 8px #f09d59, 0 0 14px #ee8a39,
		0 0 16px #f7770e, inset 0 0 3px #ff7300;
	--textShadowBlack: 0 0 1px black, 0 0 9px #6b2b00, 0 0 14px #c06d00,
		0 0 27px #f5a613, 0 0 42px #fa903a, 0 0 32px #fdbe89, 0 0 32px #eeccb0;
}

.basic {
	display: grid;
	grid-template-areas:
		"header header header header header"
		"nav article article article article"
		"nav footer footer footer footer";
	grid-template-columns: 50px 1fr 1fr 1fr 1fr; /* Устанавливаем ширину навигации 50px и остальное пространство для артикла */
	grid-template-rows: auto 1fr auto; /* Высота навигации и артикла по содержимому, футер прилипает к низу */
	min-height: 100vh; /* Минимальная высота для всего грида, чтобы футер мог прилипнуть к низу */
	text-shadow: 3px 1px 5px #000000;
}
@keyframes slideWave {
	0% {
		background-position: left;
	}
	100% {
		background-position: right;
	}
}

.header {
	grid-area: header;
	background-image: url("./img/WaveHaikei.svg");
	background-repeat: repeat-x;
	background-size: cover; /* Заполнить весь контейнер, может вызвать обрезку краев SVG */
	height: 100px;
	animation: slideWave 50s linear infinite alternate;
}

.nav {
	grid-area: nav;
	background-color: #001122;
	color: white;
	/* Убираем width и height, так как ширина уже установлена в grid-template-columns */
}

.article {
	grid-area: article;
	background-color: #001122;
	color: aliceblue;
	/* Высота по содержимому, убираем 100vh */
}

.footer {
	grid-area: footer;
	background-color: rgb(22, 21, 21);
	color: #f7770e;
	/* Прилипание к низу через grid */
}
/* Стилизация скроллбара для всех элементов */
::-webkit-scrollbar {
	width: 8px; /* ширина скроллбара */
}

/* Дорожка скроллбара */
::-webkit-scrollbar-track {
	background: #f17603; /* цвет фона */
	border-radius: 10px; /* скругление углов */
}

/* Ручка скроллбара */
::-webkit-scrollbar-thumb {
	background: #fca32f; /* цвет ручки */
	border-radius: 10px; /* скругление углов */
	border: 2px solid #000000; /* создаем бордюр вокруг ручки */
}

/* Стиль ручки при наведении */
::-webkit-scrollbar-thumb:hover {
	background: #ff0000; /* цвет ручки при наведении */
}

.form-container {
	background-color: #ffa500;
	padding: 18px;
	border-radius: 17px;
	width: 80%;
	margin: 18px auto;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	display: flex;
	flex-direction: column;
	gap: 10px;
}
.block_4_flex {
	display: flex;
	flex-direction: column;
}
.Block_4FlexBoxRegistration {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	margin-bottom: 40px;
}
.Block_4FlexBox {
	display: flex;
	align-items: flex-start;
	justify-content: space-evenly;
	padding: 25px 10px;
	background-image: url("Hexagon.svg");
	background-size: cover; /* или используй 100% 100%, чтобы изображение растягивалось на весь элемент */
	background-repeat: no-repeat; /* чтобы изображение не повторялось */
}
.Hello {
	color: #df770f;
	text-shadow: var(--textShadowBlack);
	font-size: clamp(19px, 2vw, 30px);
	border-bottom: 2px solid #df770f;
	box-shadow: 1 0 1 #f59434, 1 0 2 #df770f80, 1 0 3 #ffd5ab49;
	margin: 15px auto 15px;
	animation: pulsate 5s infinite alternate;
}

.Block_4FlexBoxLogin {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 10px;
	margin-bottom: 40px;
}
.block_4_login {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.block_4_Change {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	margin-bottom: 40px;
}
.form-header {
	color: #ffffff;
	margin-bottom: 1em;
	font-size: 22px;
	text-align: center;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"] {
	padding: 18px;
	margin: 24px auto 5px;
	border: 1px solid transparent;
	border-radius: 15px;
	font-size: clamp(14px, 2vw, 22px);
	width: 50%;
	box-shadow: var(--boxShadow);

	min-width: 280px;
}

::placeholder {
	color: #df770f;
	text-shadow: 0 0 1 black;
}
form .button {
	width: 30%;
	min-width: 280px;
	margin: 15px auto;
	transition: 0.5s;
	text-shadow: 2px 2px 2px black;
}

input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="tel"]:focus {
	outline: none;
	border: 1px solid #000000;
	background-color: rgb(235, 223, 202);
}
.formsended {
	height: auto;
	margin: 0 auto 25px;
}
form p {
	color: red;
	font-size: 14px;
	margin-bottom: 25px;
	text-shadow: 2px 2px 2px black;
	border-bottom: 2px solid #df770f;
}
.dataSubmitted {
	color: red;
	font-size: 14px;
	margin: 20px auto;
}
.dataKey {
	color: orange;
	font-size: 14px;
}
.dataSpan {
	color: rgb(255, 255, 255);
	font-size: 12px;
}
.labelRadio {
	display: flex;
	padding: 10px;
	margin: 10px auto 10px auto;
	gap: 40px;
}

.checkStyle {
	color: #df770f;
}
.nonStyle {
	color: #dddddd;
}
@media screen and (max-width: 950px) {
	.Block_4FlexBox {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-evenly;
	}
}

.useForm {
	animation: inputAnimation 3s ease 0s 1 normal forwards;
	text-shadow: var(--textShadow);
}
.inputAnimation_0 {
	animation: inputAnimation 1s ease 0s 1 normal forwards;

	text-shadow: var(--textShadow);
}
.inputAnimation_1 {
	animation: inputAnimation 1.2s ease 0s 1 normal forwards;
}
.inputAnimation_2 {
	animation: inputAnimation 1.4s ease 0s 1 normal forwards;
}
.inputAnimation_3 {
	animation: inputAnimation 1.6s ease 0s 1 normal forwards;
}
.inputAnimation_4 {
	animation: inputAnimation 1.8s ease 0s 1 normal forwards;
}
.inputAnimation_5 {
	animation: inputAnimation 2s ease 0s 1 normal forwards;
}
.inputAnimation_60 {
	animation: inputAnimation 2.2s ease 0s 1 normal forwards;
}
.inputAnimation_7 {
	animation: inputAnimation 2.4s ease 0s 1 normal forwards;
}
.inputAnimation_8 {
	animation: inputAnimation 2.6s ease 0s 1 normal forwards;
}
.inputAnimation_9 {
	animation: inputAnimation 2.8s ease 0s 1 normal forwards;
}
.inputAnimation_10 {
	animation: inputAnimation 3s ease 0s 1 normal forwards;
}
.inputAnimation_11 {
	animation: inputAnimation 1.2s ease 0s 1 normal forwards;

	text-shadow: var(--textShadow);
}
.inputAnimation_12 {
	animation: inputAnimation 1.4s ease 0s 1 normal forwards;
}
.inputAnimation_13 {
	animation: inputAnimation 1.6s ease 0s 1 normal forwards;
}
.inputAnimation_14 {
	animation: inputAnimation 1.8s ease 0s 1 normal forwards;
}
.inputAnimation_15 {
	animation: inputAnimation 2s ease 0s 1 normal forwards;
}
.inputAnimation_16 {
	animation: inputAnimation 1s ease 0s 1 normal forwards;

	text-shadow: var(--textShadow);
}
.inputAnimation_17 {
	animation: inputAnimation 1.2s ease 0s 1 normal forwards;
}
.inputAnimation_18 {
	animation: inputAnimation 1.4s ease 0s 1 normal forwards;
}
.inputAnimation_19 {
	animation: inputAnimation 1.6s ease 0s 1 normal forwards;
}
.inputAnimation_20 {
	animation: inputAnimation 1.8s ease 0s 1 normal forwards;
}
.inputAnimation_21 {
	animation: inputAnimation 2.2s ease 0s 1 normal forwards;
}
.inputAnimation_22 {
	animation: inputAnimation 2.4s ease 0s 1 normal forwards;
}
@keyframes inputAnimation {
	0% {
		opacity: 0;
		transform: translateX(-80px);
	}
	25% {
		opacity: 1;
	}

	100% {
		transform: translateX(0);
	}
}
.chat-name_input {
	padding: 8px;
	margin: 8px auto 8px;
	border: 1px solid transparent;
	border-radius: 8px;
	font-size: clamp(12px, 2vw, 18px);
	width: 24%;
	box-shadow: var(--boxShadow);
	min-width: 50px;
}
.chat-form__input {
	padding: 8px;
	margin: 8px auto 8px;
	border: 1px solid transparent;
	border-radius: 8px;
	font-size: clamp(12px, 2vw, 18px);
	width: 48%;
	box-shadow: var(--boxShadow);
	min-width: 50px;
}
